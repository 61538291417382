<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import addButton from "../../../components/widgets/addButton.vue";
import programmeCard from "../../../components/widgets/programmeCard.vue";
import { Erreur } from "../../../helpers/error";
import { Api } from "../../../helpers";
import Swal from "sweetalert2";
import loaderProgress from "../../../components/widgets/loaderProcess.vue";
/**
 * Projects-create component
 */
export default {
  page: {
    title: "Video list",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    addButton,
    programmeCard,
    loaderProgress,
  },
  data() {
    return {
      addMovieModal: false,
      modalAddProgram: false,
      process: false,
      spinner: false,
      progress: 0,
      titre: "",
      title: "Programmes",
      programmes: [],
      items: [
        {
          text: "Programmes",
          active: true,
        },
      ],
      programme: {
        titre: "",
        description: "",
        duree: "",
      },
    };
  },
  methods: {
    showAddMovieModal() {
      this.addMovieModal = !this.addMovieModal;
    },
    showModalAddProgram() {
      this.modalAddProgram = !this.modalAddProgram;
    },

    imagePortrait(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        const originalFile = event.target.files[i];
        const newName = `${i}${originalFile.name}${new Date().getTime()}`;

        // Créer un nouveau fichier avec le nouveau nom
        const renamedFile = new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });

        this.imagePort = renamedFile;
      }

      console.log(this.imagePort);
    },
    imagePaysage(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        const originalFile = event.target.files[i];
        const newName = `${i}${originalFile.name}${new Date().getTime()}`;

        // Créer un nouveau fichier avec le nouveau nom
        const renamedFile = new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });

        this.imagePay = renamedFile;
      }

      console.log(this.imagePay);
    },
    videoSelect(event) {
      this.video = event.target.files[0];
      console.log(this.video);
      for (let i = 0; i < event.target.files.length; i++) {
        const originalFile = event.target.files[i];
        const newName = `${i}${originalFile.name}${new Date().getTime()}`;

        // Créer un nouveau fichier avec le nouveau nom
        const renamedFile = new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });

        this.video = renamedFile;
      }

      console.log(this.video);
    },
    createProgram() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(this.progress);
      };
      console.log(this.programme);
      this.modalAddProgram = false;
      this.process = true;
      this.titre = "Ajout du programme";
      Api.postFormData(
        `/streamvod/rest/v2/program/create?description=${this.programme.description}
        &duration=${this.programme.duree}&title=${this.programme.titre}`,
        {
          landscape: this.imagePay,
          portrait: this.imagePort,
          video_cover: this.video,
        },
        onUploadProgress
      )
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Programme ajoutée", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    this.spinner = true;
    Api.get("/streamvod/api/v2/program/all")
      .then((res) => {
        this.spinner = false;
        this.programmes = res.data.content;
        console.log(res);
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loaderProgress :visible="process" :progress="progress" :title="titre" />

    <div
      style="display: flex; justify-content: space-between; margin-bottom: 2rem"
    >
      <div></div>
      <addButton
        @click="showModalAddProgram()"
        :text="'Ajouter un programme'"
      ></addButton>
      <!-- <button class="btn" style="background-color: #07693a; padding: 0%">
        <b-dropdown
          class="mb-0"
          toggle-class="btn btn-link text-muted"
          menu-class="dropdown-menu-end"
          right
          variant="white"
        >
          <template #button-content style="background-color: #07693a">
            <i class="fas fa-plus font-size-18" style="color: white"></i>
          </template>
          <b-dropdown-item @click="showModalAddProgram()"
            ><span
              ><i class="fas fa-plus font-size-18"></i> Ajouter un
              programme</span
            ></b-dropdown-item
          >
          <b-dropdown-item @click="showAddMovieModal()"
            ><span
              ><i class="fas fa-plus font-size-18"></i> Ajouter une section de
              programme</span
            ></b-dropdown-item
          >
        </b-dropdown>
      </button> -->
    </div>

    <b-modal
      v-model="addMovieModal"
      id="modal-lg"
      size="s"
      title="Que souhaitez vous faire ?"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <div class="row">
          <button
            type="submit"
            style="border-radius: 8px; width: 100%"
            class="btn c2play-primary"
            @click="showImportModalRef"
          >
            Créer une nouvelle section
          </button>
        </div>
        <div class="row mt-2">
          <button
            type="submit"
            style="border-radius: 8px; width: 100%"
            class="btn c2play-primary"
            @click="showImportModalRef"
          >
            Modifier une section existante
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="modalAddProgram"
      id="modal-xl"
      size="xl"
      title="Ajouter un programme"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>
        <form>
          <div class="row">
            <div class="form-group col-lg-6 mb-3">
              <label for="projectname" class="col-form-label">Titre</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  v-model="programme.titre"
                  placeholder="Entrez le titre du programme"
                />
              </div>
            </div>

            <div class="form-group col-lg-6 mb-3">
              <label class="col-form-label">Durée du programme</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  v-model="programme.duree"
                  class="form-control"
                  placeholder="hh:mm:ss"
                />
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="form-group col-lg-4">
              <label for="projectbudget" class="col-form-label"
                >Image Portrait</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="imagePortrait($event)"
                  class="form-control"
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>

            <div class="form-group col-lg-4">
              <label for="projectbudget" class="col-form-label"
                >Image plateau (Paysage)</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="imagePaysage($event)"
                  class="form-control"
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>
            <div class="form-group col-lg-4">
              <label for="projectbudget" class="col-form-label"
                >Vidéo de couverture</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="videoSelect($event)"
                  class="form-control"
                  accept=".MKV, .AVI, .MP4, MOV"
                />
              </div>
            </div>
          </div>

          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label"
              >Description</label
            >

            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="programme.description"
                placeholder="Entrez la description"
              ></textarea>
            </div>
          </div>
        </form>
        <div style="display: flex; justify-content: center">
          <addButton :text="'Ajouter'" @click="createProgram()"></addButton>
        </div>
      </div>
    </b-modal>

    <div class="row">
      <programmeCard
        v-for="programme in programmes.slice().reverse()"
        :key="programme.id"
        :one-programme="programme"
      ></programmeCard>

      <div style="text-align: center; font-size: 3em" v-if="spinner == true">
        <b-spinner variant="success" role="status"></b-spinner>
      </div>
      <div
        style="text-align: center; font-size: 3em"
        v-if="spinner == false && programmes.length == 0"
      >
        <span style="font-size: 1.5rem">Aucun programme créé !</span>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
